import React, { MutableRefObject, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { between } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'

import Utils from 'Utils'

import NotificationsButton from 'Components/Blocks/Community/NotificationsButton'
import CommunitySelector from 'Components/Blocks/CommunitySelector'
import CreateCommunityUserModal from 'Components/Blocks/Modals/CreateCommunityUser'
import SidebarUser from 'Components/Blocks/SidebarUser'
import { Link, MenuItem, Row } from 'Components/UI'

import {
  PERMISSION_ACTION,
  PERMISSION_SCOPES,
  PERMISSION_SUBJECT,
} from 'Constants/permissions'

import { useCommunity, useEntityModal, usePermission } from 'Hooks'

import {
  COMMUNITY,
  COMMUNITY_DASHBOARD,
  COMMUNITY_MANAGEMENT,
  COMMUNITY_METRICS,
  COMMUNITY_PANEL,
  COMMUNITY_UPLOAD_DATA,
  ROOT,
} from 'Router/routes'

import CreateUserButton from './CreateUserButton'
import HumburgerMenu, { ILink } from './HamburgerMenu'
import InviteManagementButton from './InviteManagementButton'
import { Logo, LogoWrapper, Menu } from './styles'

const RESTRICTED_LINKS: ILink[] = [{ to: COMMUNITY_PANEL, title: 'Community' }]

export interface IHeader {
  menuHidden?: boolean
  restricted?: boolean
  onOpenInvitePanel?: () => void
  onOpenNotifications?: () => void
  notificationsButtonRef?: MutableRefObject<HTMLButtonElement | null>
  invitationsButtonRef?: MutableRefObject<HTMLButtonElement | null>
}

const Header = ({
  menuHidden = false,
  restricted = false,
  onOpenInvitePanel,
  onOpenNotifications,
  notificationsButtonRef,
  invitationsButtonRef,
}: IHeader) => {
  const location = useLocation()
  const { community } = useCommunity()
  const { can } = usePermission()

  const isShowHamburgerButton = useBreakpoint(between('md', 'xl'))

  const linksList: ILink[] = useMemo(() => {
    if (restricted) return RESTRICTED_LINKS

    const DEFAULT_LINKS = community?.slug
      ? [
          {
            to: COMMUNITY_PANEL,
            title: 'Communities',
          },
          {
            to: Utils.URL.generatePath(COMMUNITY_MANAGEMENT, {
              slug: community?.slug,
            }),
            title: 'Management',
          },
        ]
      : [
          {
            to: COMMUNITY_PANEL,
            title: 'Communities',
          },
        ]

    const canViewMetrics = can(
      PERMISSION_ACTION.READ,
      PERMISSION_SUBJECT.METRICS,
      [],
    )

    const canViewUploadData = can(
      PERMISSION_ACTION.READ,
      PERMISSION_SUBJECT.UPLOAD_DATA,
      [],
    )

    const links = [...DEFAULT_LINKS]

    if (canViewMetrics && community?.slug) {
      links.splice(1, 0, {
        to: Utils.URL.generatePath(COMMUNITY_METRICS, {
          slug: community?.slug,
        }),
        title: 'Metrics',
      })
    }

    if (canViewUploadData && community?.slug)
      links.splice(2, 0, {
        to: Utils.URL.generatePath(COMMUNITY_UPLOAD_DATA, {
          slug: community?.slug,
        }),
        title: 'Upload Data',
      })

    return links.map(link => {
      const isCommunityDashboardMatch = matchPath(
        {
          path: COMMUNITY_DASHBOARD,
        },
        location.pathname,
      )

      if (
        (link.to === COMMUNITY && isCommunityDashboardMatch) ||
        link.to === location.pathname
      )
        return { ...link, isActive: true }

      return link
    })
  }, [can, community?.slug, location.pathname, restricted])

  const [createModal, createActions] = useEntityModal()

  const handleCreateModalOpen = createActions.openModal
  const handleCreateModalClose = createActions.closeModal

  const canCreateUser =
    can(
      PERMISSION_ACTION.CREATE,
      PERMISSION_SUBJECT.ACCOUNT,
      PERMISSION_SCOPES.ACCOUNT_OWN_CREATOR,
    ) && !menuHidden

  const canViewInvites = can(
    PERMISSION_ACTION.READ,
    PERMISSION_SUBJECT.INVITE,
    [],
  )

  return (
    <Menu>
      {isShowHamburgerButton ? (
        <>
          <Row center>
            {restricted ? (
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            ) : (
              <Link to={ROOT}>
                <LogoWrapper>
                  <Logo />
                </LogoWrapper>
              </Link>
            )}
            {!menuHidden && (
              <>
                <CommunitySelector ml={3} mr={4} />
                <HumburgerMenu links={linksList} />
              </>
            )}
          </Row>

          <Row center gap={3}>
            {canCreateUser && (
              <CreateUserButton onClick={handleCreateModalOpen} />
            )}

            {!restricted && (
              <NotificationsButton onOpen={onOpenNotifications} />
            )}
            {canViewInvites && !restricted && (
              <InviteManagementButton onOpenInvitePanel={onOpenInvitePanel} />
            )}
            <SidebarUser />
          </Row>
        </>
      ) : (
        <Row center fullWidth>
          <Link to={ROOT}>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </Link>
          {!menuHidden && (
            <>
              {community && (
                <MenuItem
                  caption={community?.name}
                  isActive={
                    location.pathname ===
                    Utils.URL.generatePath(COMMUNITY_DASHBOARD, {
                      slug: community?.slug,
                    })
                  }
                  ml={3}
                  mr={2}
                  to={Utils.URL.generatePath(COMMUNITY_DASHBOARD, {
                    slug: community?.slug,
                  })}
                />
              )}

              <Row gap={1}>
                {linksList.map(item => (
                  <MenuItem
                    caption={item.title}
                    isActive={item.isActive}
                    key={item.to}
                    to={item.to}
                  />
                ))}
              </Row>
            </>
          )}

          <Row center gap="10px" ml="auto" noShrink>
            {canCreateUser && (
              <CreateUserButton onClick={handleCreateModalOpen} />
            )}

            {!restricted && !menuHidden && (
              <NotificationsButton
                ref={notificationsButtonRef}
                onOpen={onOpenNotifications}
              />
            )}
            {canViewInvites && !restricted && (
              <InviteManagementButton
                ref={invitationsButtonRef}
                onOpenInvitePanel={onOpenInvitePanel}
              />
            )}
            <SidebarUser />
          </Row>
        </Row>
      )}
      <CreateCommunityUserModal
        isOpen={createModal.isOpen}
        onClose={handleCreateModalClose}
      />
    </Menu>
  )
}

export default Header
