import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import { IconChevronRight, IconEye } from '@tabler/icons-react'
import readNotificationMutation from 'GraphQL/Mutations/Notification/readNotification.graphql'
import { readNotificationUpdater } from 'GraphQL/Updaters/Notification'
import { unreadNotificationsDecreaseCountUpdater } from 'GraphQL/Updaters/UnreadNotificationsCount'
import { DateTime } from 'luxon'
import Utils from 'Utils'
import { getFullName } from 'Utils/User'

import {
  Avatar,
  Column,
  IconButton,
  Row,
  Tag,
  Text,
  Tooltip,
} from 'Components/UI'

import { NOTIFICATION_KIND } from 'Constants/ids'
import { TAG_COLOR_KIND } from 'Constants/tags'

import { useCommunityContext, useResponsiveLayout } from 'Hooks'

import { TABS as MANAGEMENT_TABS } from 'Pages/Community/Management/constants'

import { COMMUNITY_MANAGEMENT } from 'Router/routes'

import { ProposalDetails } from './Details'
import { AlertIcon, Container, IconCircle, InnerContainer } from './styles'

function NotificationCell({ notification }) {
  const { isMobile } = useResponsiveLayout()
  const navigate = useNavigate()
  const { community } = useCommunityContext()

  const [readNotification] = useMutation(readNotificationMutation)

  const [isDetailed, setDetailed] = useState(false)

  const { photoUrl, fullName } = useMemo(() => {
    const user = notification?.creator?.profile
      ? notification.creator.profile
      : notification.user?.profile

    return {
      photoUrl: user.photoUrl,
      fullName: getFullName(user),
    }
  }, [notification])

  const createdAt = notification?.createdAt
  const message = notification?.message
  const read = notification?.read
  const kind = notification?.kind
  const proposalId = notification?.meta?.proposalId

  const handleRedirectTo = useCallback(() => {
    switch (kind) {
      case NOTIFICATION_KIND.PROPOSAL:
        navigate(
          `${Utils.URL.generatePath(COMMUNITY_MANAGEMENT, {
            slug: community.slug,
          })}?tab=${MANAGEMENT_TABS.PROPOSALS}`,
        )
        break
      case NOTIFICATION_KIND.SHARED_NOTE:
        navigate(
          `${Utils.URL.generatePath(COMMUNITY_MANAGEMENT, {
            slug: community.slug,
          })}?tab=${MANAGEMENT_TABS.NOTES}`,
        )
        break
      default:
        break
    }
  }, [community.slug, navigate, kind])

  const handleRead = useCallback(async () => {
    if (!read) {
      await readNotification({
        variables: {
          id: notification?.id,
        },
        update: store => {
          readNotificationUpdater(store, notification)
          unreadNotificationsDecreaseCountUpdater(store, community.id)
        },
      })
    }

    if (kind === NOTIFICATION_KIND.PROPOSAL) {
      setDetailed(true)
    }
  }, [read, kind, readNotification, notification, community])

  const title = useMemo(() => {
    switch (kind) {
      case NOTIFICATION_KIND.PROPOSAL:
        return 'Proposal'
      case NOTIFICATION_KIND.NYLAS_GRANT_EXPIRED:
        return 'Nylas'
      default:
        return null
    }
  }, [kind])

  const tagKind = useMemo(() => {
    switch (kind) {
      case NOTIFICATION_KIND.PROPOSAL:
        return TAG_COLOR_KIND.PROPOSAL
      case NOTIFICATION_KIND.NYLAS_GRANT_EXPIRED:
        return TAG_COLOR_KIND.MESSAGE
      default:
        return TAG_COLOR_KIND.CUSTOM
    }
  }, [kind])

  return (
    <Container isRead={read}>
      <InnerContainer>
        <Column fullHeight justifyCenter spaceBetween>
          {kind === NOTIFICATION_KIND.NYLAS_GRANT_EXPIRED ? (
            <IconCircle>
              <AlertIcon />
            </IconCircle>
          ) : (
            <Avatar src={photoUrl} />
          )}
        </Column>

        <Column fullWidth ml={2}>
          <Row center fullWidth>
            <Column mr={2}>
              <Row center>
                <Tooltip content={`${fullName} ${message}`}>
                  <Text header header4={!read}>
                    {fullName} {message}
                  </Text>
                </Tooltip>

                {!isMobile && (
                  <Tag colorKind={tagKind} ml={2} small text={title} />
                )}
              </Row>

              <Row center>
                {isMobile && (
                  <Tag colorKind={tagKind} mr={1} small text={title} />
                )}
                <Text body bodySmall>
                  {DateTime.fromISO(createdAt).toRelative()}
                </Text>
              </Row>
            </Column>

            <Row center gap={1} ml="auto">
              <IconButton outline onClick={handleRead}>
                <IconEye />
              </IconButton>

              <IconButton secondary onClick={handleRedirectTo}>
                <IconChevronRight size={16} />
              </IconButton>
            </Row>
          </Row>
        </Column>
      </InnerContainer>
      {isDetailed && <ProposalDetails proposalId={proposalId} />}
    </Container>
  )
}

NotificationCell.defaultProps = {
  notification: {},
}

NotificationCell.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    createdAt: PropTypes.string,
    read: PropTypes.bool,
    kind: PropTypes.string,
    meta: PropTypes.object,
    creator: PropTypes.shape({
      profile: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        photoUrl: PropTypes.string,
      }),
    }),
    user: PropTypes.shape({
      profile: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        photoUrl: PropTypes.string,
      }),
    }),
  }),
}

export default NotificationCell
