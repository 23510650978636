import { v4 as uuidv4 } from 'uuid'

const EventBus = (() => {
  const subscriptions = new Map<
    string,
    Map<string, { callback: (...args: unknown[]) => void }>
  >()

  return {
    actions: {
      graph: {
        clickItem: 'graph/clickItem',
        setTooltip: 'graph/setTooltip',
        expandUser: 'graph/expandUser',
        expandKnowledge: 'graph/expandKnowledge',
        findPath: 'graph/findPath',
        search: 'graph/search',
        expandCommunity: 'graph/expandCommunity',
        addUserById: 'graph/addUserById',
        addUsersById: 'graph/addUsersById',
        addOrganizationById: 'graph/addOrganizationById',
        connectUser: 'graph/connectUser',
        connectSkillTag: 'graph/connectSkillTag',
        disconnectSkillTag: 'graph/disconnectSkillTag',
        addSkillTags: 'graph/addSkillTags',
        removeItemsByIds: 'graph/removeItemsByIds',
        disconnectUser: 'graph/disconnectUser',
        focusNode: 'graph/focusNode',
        removeSelected: 'graph/removeSelected',
        viewSelected: 'graph/viewSelected',
        addCommunityEdge: 'graph/addCommunityEdge',
      },
      chat: {
        replyToMessage: 'chat/reply-to-message',
        editMessage: 'chat/edit-message',
        deleteMessage: 'chat/delete-message',
        openParticipants: 'chat/open-participants',
      },
      zoom: {
        startMeeting: 'zoom/start-meeting',
        leaveMeeting: 'zoom/leave-meeting',
      },
      search: {
        community: 'search/community',
        reset: 'search/reset',
      },
      profile: {
        open: 'profile/open',
        reload: 'profile/reload',
        openOwn: 'profile/openOwn',
        openRightUserPanelProfile: 'profile/openRightUserPanelProfile',
        closeRightUserPanelProfile: 'profile/closeRightUserPanelProfile',
      },
      dashboard: {
        setFilters: 'dashboard/setFilters',
        resetFilters: 'dashboard/resetFilters',
        closePopover: 'dashboard/closePopover',
        setRelationshipsStrength: 'dashboard/setRelationshipsStrength',
        resetRelationshipsStrength: 'dashboard/resetRelationshipsStrength',
        refetch: 'dashboard/refetch',
        introduceTo: 'dashboard/introduceTo',
        createQuickNote: 'dashboard/createQuickNote',
        deleteNote: 'dashboard/deleteNote',
        browseUsers: 'dashboard/browseUsers',
        search: 'dashboard/search',
        addToCommunity: 'dashboard/addToCommunity',
        addTagsToUser: 'dashboard/addTagsToUser',
        updateContact: 'dashboard/updateContact',
        createGraphSnapshot: 'dashboard/createGraphSnapshot',
        updateGraphSnapshot: 'dashboard/updateGraphSnapshot',
        deleteGraphSnapshot: 'dashboard/deleteGraphSnapshot',
        manageGraphSnapshots: 'dashboard/manageGraphSnapshots',
      },
      mentionsTextarea: {
        addElement: 'mentionsTextarea/addElement',
      },
    },

    on(event: string, callback: (...args: unknown[]) => void) {
      if (!subscriptions.has(event)) {
        subscriptions.set(event, new Map())
      }

      const subscriptionId = uuidv4()

      subscriptions.get(event)?.set(subscriptionId, { callback })

      return {
        clear: () => {
          const eventSubscriptions = subscriptions.get(event)
          eventSubscriptions?.delete(subscriptionId)
          if (eventSubscriptions?.size === 0) {
            subscriptions.delete(event)
          }
        },
      }
    },

    trigger(event: string, ...args: unknown[]) {
      subscriptions.get(event)?.forEach(subscription => {
        subscription.callback(...args)
      })
    },
  }
})()

export default EventBus
