export const TABS = {
  ACCOUNTS: 'Accounts',
  ADMIN: 'Admin',
  API_KEYS: 'API Keys',
  HUBSPOTS: 'HubSpots',
  NOTES: 'Notes',
  PROPOSALS: 'Proposals',
  ROLE_BASED_ACCESS: 'Role based access',
  SKILLS: 'Skills',
  TAGS: 'Tags',
}
