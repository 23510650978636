import React, { useCallback, useState } from 'react'
import { DateRange } from 'react-day-picker'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'
import { setMinSearchLength } from 'Utils/Form'
import { graphUsersToValues, IGraphUserValue } from 'Utils/User'

import map from 'lodash/map'

import { Column, DateRangePickerInput, Select } from 'Components/UI'

import { DEFAULT_MIN_SEARCH_SIZE, DEFAULT_SEARCH_DEBOUNCE } from 'Constants/ids'

import { useCommunityContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { AdvancedFilter, FilterDropdown } from '../../Blocks'

export interface IImportanceOption {
  label: string
  value: boolean
}

const IMPORTANCE_OPTIONS: IImportanceOption[] = [
  { label: 'Important', value: true },
  { label: 'Not Important', value: false },
]

export interface IReadUnreadOption {
  label: string
  value: boolean
}

const READ_UNREAD_OPTIONS: IReadUnreadOption[] = [
  { label: 'Read', value: true },
  { label: 'Unread', value: false },
]

export interface ISharedOption {
  label: string
  value: string
}

const FILTER = {
  CREATORS: 'creators',
  SUBJECTS: 'subjects',
  DATE: 'date',
  IMPORTANCE: 'importance',
  READ: 'read',
  SHARED: 'shared',
}

export interface IFilters {
  createdTo?: string | undefined
  createdFrom?: string | undefined
  creatorIds?: string[] | undefined
  receiverIds?: string[] | undefined
  favorite?: boolean | undefined
  read?: boolean | undefined
}

export interface IFilterProps {
  onChange: (value: IFilters) => void
}

// TODO: Refactor this to use FINAL-FORM
function Filter({ onChange }: IFilterProps) {
  const s = useScopedI18n('general')
  const { community } = useCommunityContext()
  const client = useApolloClient()

  const [creators, setCreators] = useState<IGraphUserValue[]>([])
  const [receivers, setReceivers] = useState<IGraphUserValue[]>([])
  const [date, setDate] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  })
  const [importance, setImportance] = useState<IImportanceOption | null>(null)
  const [read, setRead] = useState<IReadUnreadOption | null>(null)
  const [shared, setShared] = useState<ISharedOption | null>(null)

  const [openFilter, setOpenFilter] = useState<string | null>(null)

  const handleChangeDate = useCallback((value?: DateRange) => {
    setDate(value)
  }, [])

  const clearAll = useCallback(() => {
    setCreators([])
    setReceivers([])
    setImportance(null)
    setRead(null)
    setDate({
      from: undefined,
      to: undefined,
    })
    setShared(null)
    onChange({
      createdFrom: undefined,
      createdTo: undefined,
    })
  }, [onChange])

  const applyFilters = () => {
    onChange({
      createdTo: date?.to?.toString(),
      createdFrom: date?.from?.toString(),
      creatorIds:
        creators.length > 0
          ? map(creators, creator => creator.value)
          : undefined,
      receiverIds:
        receivers.length > 0
          ? map(receivers, receiver => receiver.value)
          : undefined,
      favorite: importance?.value,
      read: read?.value,
    })
  }

  const loadUsersOptions = useCallback(
    () =>
      async (
        inputValue: string,
        callback: (values: IGraphUserValue[]) => void,
      ) => {
        const result = await client.query<
          Pick<MainSchema.Query, 'listCommunityUsers'>,
          MainSchema.QueryListCommunityUsersArgs
        >({
          query: listCommunityUsersQuery,
          variables: community
            ? {
                communityId: community.id,
                search: inputValue,
                limit: 25,
              }
            : undefined,
        })

        const usersSuggestions =
          result.data?.listCommunityUsers?.communityUsers || []

        callback(graphUsersToValues(usersSuggestions))
      },
    [community, client],
  )

  const debouncedLoadOptions = useCallback(
    () =>
      setMinSearchLength(
        debounce(loadUsersOptions(), DEFAULT_SEARCH_DEBOUNCE),
        DEFAULT_MIN_SEARCH_SIZE,
      ),
    [loadUsersOptions],
  )

  return (
    <AdvancedFilter applyFilters={applyFilters} clearFilter={clearAll}>
      <Column>
        <FilterDropdown
          isOpened={openFilter === FILTER.CREATORS}
          setIsOpened={() =>
            setOpenFilter(
              openFilter === FILTER.CREATORS ? null : FILTER.CREATORS,
            )
          }
          title={
            creators.length
              ? s('creatorsCount', { count: creators.length })
              : s('creators')
          }
        >
          <Select
            async
            isMulti
            loadOptions={debouncedLoadOptions()}
            placeholder=""
            value={creators}
            width={1}
            withLabel={false}
            // TODO: replace any with actual type
            onChange={(value: any) => setCreators(value)}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTER.SUBJECTS}
          setIsOpened={() =>
            setOpenFilter(
              openFilter === FILTER.SUBJECTS ? null : FILTER.SUBJECTS,
            )
          }
          title={
            receivers.length
              ? s('subjectsCount', { count: receivers.length })
              : s('subjects')
          }
        >
          <Select
            async
            isMulti
            loadOptions={debouncedLoadOptions()}
            placeholder=""
            value={receivers}
            width={1}
            withLabel={false}
            // TODO: replace any with actual type
            onChange={(value: any) => setReceivers(value)}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTER.DATE}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTER.DATE ? null : FILTER.DATE)
          }
          title={s('date')}
        >
          <DateRangePickerInput
            dateRange={date}
            width={1}
            onChange={handleChangeDate}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTER.IMPORTANCE}
          setIsOpened={() =>
            setOpenFilter(
              openFilter === FILTER.IMPORTANCE ? null : FILTER.IMPORTANCE,
            )
          }
          title="Importance"
        >
          <Select
            blurInputOnSelect
            isClearable
            options={IMPORTANCE_OPTIONS}
            placeholder=""
            value={importance}
            width={1}
            withLabel={false}
            // TODO: replace any with actual type
            onChange={(value: any) => setImportance(value)}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTER.READ}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTER.READ ? null : FILTER.READ)
          }
          title="Read/Unread"
        >
          <Select
            blurInputOnSelect
            isClearable
            options={READ_UNREAD_OPTIONS}
            placeholder=""
            value={read}
            width={1}
            withLabel={false}
            // TODO: replace any with actual type
            onChange={(value: any) => setRead(value)}
          />
        </FilterDropdown>

        <FilterDropdown
          isOpened={openFilter === FILTER.SHARED}
          setIsOpened={() =>
            setOpenFilter(openFilter === FILTER.SHARED ? null : FILTER.SHARED)
          }
          title="Shared"
        >
          <Select
            blurInputOnSelect
            isClearable
            value={shared}
            width={1}
            withLabel={false}
            // TODO: replace any with actual type
            onChange={(value: any) => setShared(value)}
          />
        </FilterDropdown>
      </Column>
    </AdvancedFilter>
  )
}

export default Filter
