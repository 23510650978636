import React from 'react'
import { RouteObject } from 'react-router-dom'

import { CommunityLayout } from 'Components/Layout'

import Community from 'Pages/Community'

import * as ROUTES from './routes'

export const RESTRICTED_COMMUNITY_ROUTES: RouteObject = {
  element: <CommunityLayout />,
  path: ROUTES.COMMUNITY,
  children: [
    {
      element: <Community.Redirector />,
      path: ROUTES.COMMUNITY,
    },

    {
      element: <Community.Communities />,
      path: ROUTES.COMMUNITY_PANEL,
    },
  ],
}

export const getCommunityRoutes = (): RouteObject => {
  const routes: RouteObject[] = [
    {
      element: <Community.Redirector />,
      path: ROUTES.COMMUNITY,
    },
    {
      element: <Community.Communities />,
      path: ROUTES.COMMUNITY_PANEL,
    },
    {
      element: <Community.Dashboard />,
      path: ROUTES.COMMUNITY_DASHBOARD,
      children: [
        {
          element: <Community.DashboardUsers />,
          path: ROUTES.COMMUNITY_DASHBOARD,
        },
        {
          element: <Community.DashboardUser />,
          path: ROUTES.COMMUNITY_USER,
        },
      ],
    },
    {
      element: <Community.Notes />,
      path: ROUTES.COMMUNITY_NOTES,
    },
    {
      element: <Community.Notes />,
      path: ROUTES.COMMUNITY_NOTE,
    },
    {
      element: <Community.Metrics />,
      path: ROUTES.COMMUNITY_METRICS,
    },
    {
      element: <Community.Management />,
      path: ROUTES.COMMUNITY_MANAGEMENT,
    },
    {
      element: <Community.HubSpotOAuth />,
      path: ROUTES.COMMUNITY_HUBSPOT_OAUTH,
    },
    {
      element: <Community.UploadData />,
      path: ROUTES.COMMUNITY_UPLOAD_DATA,
    },
    {
      element: <Community.ComingSoon />,
      path: ROUTES.COMMUNITY_SEARCH,
    },
    {
      element: <Community.ComingSoon />,
      path: ROUTES.COMMUNITY_MANAGE,
    },
    {
      element: <Community.ComingSoon />,
      path: ROUTES.COMMUNITY_SUGGESTIONS,
    },
    {
      element: <Community.ComingSoon />,
      path: ROUTES.COMMUNITY_INVITE,
    },
    {
      element: <Community.ComingSoon />,
      path: ROUTES.COMMUNITY_ADD,
    },
  ]

  return {
    element: <CommunityLayout />,
    path: ROUTES.COMMUNITY,
    children: routes,
  }
}
